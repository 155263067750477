import { React, useState, useEffect, useContext } from 'react';
import DatastoreContext from '../../datastoreContext';
import Flickity from 'flickity';
import 'flickity/dist/flickity.css';
import './carousel.css';

// images
const blank = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="

function Carousel() {

    // database
    const ds = useContext(DatastoreContext);
    const carouselTableItemsPath = 'recode/carousel/items'
    //require('flickity-fade');

    // useState hooks
    const [items, setItems] = useState([]);
    
    // helper functions
    function makeCell(imgSrc, title, author, area, category, id) {
        const cell = document.createElement('div');
        cell.className = 'carousel-cell';
        cell.title = title;
        cell.author = author;
        //cell.link = 

        const anchor = document.createElement('a'); 
        anchor.href = ds.getReaderLink(area, category, id);

        const img = document.createElement('img');        
        img.src = imgSrc; 
        img.alt = title;

        anchor.appendChild(img);
        cell.appendChild(anchor);

        return cell;
    }        
    
    // watchers
    useEffect(() => {  
        ds.watchCollection(carouselTableItemsPath, items, setItems);           
    },[]);

    // after updating the carousel items
    useEffect(() => {   
        const flkty = new Flickity( '.carousel', {
            autoPlay: 10000,
            pauseAutoPlayOnHover: true,
            percentPosition: true,
            wrapAround: true,
            selectedAttraction: 0.02,
            friction: 0.25,
            prevNextButtons: false,
            lazyLoad: 1,
            //fade: true,
        });                   
        const cells = flkty.getCellElements()
        flkty.remove(cells) // remove the initial placeholder cells
        let cellElems = [];
        items.forEach(item => {
            cellElems.push(makeCell(
                item.imagesrc, 
                item.title, 
                item.author,
                item.area,
                item.category, 
                item.id));
        })
        flkty.append(cellElems);        
        // flkty.on( 'staticClick', ( event, pointer, cellElement, cellIndex ) => {
        //     if ( !cellElement ) { return; }
        //     alert("Navigate to: " + cellElement.link);
        // });          
        //flkty.playPlayer();
    }, [items]);    

    return (
        <div>
            <div className="carousel">
                <div className="carousel-cell"><img src={blank} alt=""/></div>
                <div className="carousel-cell"><img src={blank} alt=""/></div>
                <div className="carousel-cell"><img src={blank} alt=""/></div> 
            </div>
        </div>
    )
}

export default Carousel;