import {React} from 'react';
import './homepage.css';
import { Carousel, Shelf } from '../.';

function Homepage() {
    return (
        <>
        <div className='app-background'></div>
        <div className="homepage">          
            <Carousel />
            <Shelf shelfName="Fireside" tableItemsPath="recode/fireside/stories"/>
            <Shelf shelfName="Forest" tableItemsPath="recode/forest/paths"/>
            <Shelf shelfName="Caves" tableItemsPath="recode/caves/curios"/>
        </div>
        </>
    )
}
export default Homepage;