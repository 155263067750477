import { React, useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { NavBar } from '../components';
import Datastore from './../Datastore.ts';
import DatastoreContext from '../datastoreContext';

export default function Root() {
  const datastore = new Datastore();
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') || 'light'
  );
  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.className = theme;
  }, [theme]);
  return (
    <>
      <DatastoreContext.Provider value={datastore}>
        <div className={`${theme}`}>
          <header>
            <NavBar className='navbar' theme={theme} setTheme={setTheme} />
          </header>
          <Outlet />
          {/* FAQ */}
          {/* Footer */}
        </div>
      </DatastoreContext.Provider>
    </>
  );
}