import { React, useState, useEffect, useContext } from 'react';
import DatastoreContext from '../../datastoreContext';
import './editor.css';

// images
const blank = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="

function Editor() {
    return (
        <>
            <ShelfEditor shelfName="Carousel" shelfPath="recode/carousel/items" />
            <ShelfEditor shelfName="Fireside" shelfPath="recode/fireside/stories" />
            <ShelfEditor shelfName="Forest" shelfPath="recode/forest/paths" />
            <ShelfEditor shelfName="Caves" shelfPath="recode/caves/curios" />

            <ImageEditor shelfName="Carousel" shelfPath="recode/carousel/items" />
            <ImageEditor shelfName="Fireside" shelfPath="recode/fireside/stories" />
            <ImageEditor shelfName="Forest" shelfPath="recode/forest/paths" />
            <ImageEditor shelfName="Caves" shelfPath="recode/caves/curios" />            
        </>
    )
}

function ImageEditor(props) { // shelfPath, shelfName

    // database
    const ds = useContext(DatastoreContext);
    const storagePath = props.shelfPath; // 'recode/carousel/items'    
    const [progressPercent, setProgressPercent] = useState(0);  // hook to hold progress    
    const [imageTableItems, setImageTableItems] = useState([]); // hook for the data tables
    const [previewImageUrl, setPreviewImageUrl] = useState(blank); // hook for the preview image    

    const updateImageFileList = () => {
        ds.getStorageFileList(storagePath).then((fileList) => {
            let imageItems = [];
            fileList.forEach(filename => {
                    imageItems.push({
                        id: filename,
                        name: filename,
                        url: blank
                    });
            })
            setImageTableItems(imageItems);
        });
    };

    // load image file list into table
    useEffect(() => {
        updateImageFileList();
    }, []);

    // update thumbnails
    useEffect(() => {
        // async add thumbnail images
        imageTableItems.forEach(async item => {
            
            if (!item || !item.name) return;
            
            const url = await ds.getStorageFileURL(storagePath, item.name).then((response) => {
                if (response.status === 'success') {
                    return response.url;
                } else {
                    return blank;
                }
            })

            item.url = url;
            
            if (document.getElementById(item.id)) document.getElementById(item.id).src = url;
            
        })
    }, [imageTableItems]);


    // Data table functions
    function previewImage(fileName) {
        ds.getStorageFileURL(storagePath, fileName).then((response) => {
            if (response.status === 'success') {
                setPreviewImageUrl(response.url)
            }
        });
    }
    function removeImage(fileName) {
        ds.deleteStorageFile(storagePath, fileName).then(() => {
            updateImageFileList();
            setPreviewImageUrl(blank);
        });
    }

    // Form Submission
    const handleSubmit = (e) => {
        e.preventDefault()
        const imageFile = e.target[0]?.files[0]

        if (!imageFile) return;

        const uploadTask = ds.setStorageFileImage(storagePath, imageFile.name, imageFile);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgressPercent(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                updateImageFileList();
                ds.getStorageFileURL(storagePath, imageFile.name).then((response) => {
                    if (response.status === 'success') {
                        setPreviewImageUrl(response.url)
                    }
                });
            }
        );
    }

    return (
        <><hr />
            <div className='editor'>
                {/* shelf section */}
                <section>
                    <h2>{props.shelfName} Images</h2>
                    <ul className="image-table">
                        {imageTableItems.map((item) => {
                            return (
                                <li key={`item.id${Math.random()}`}>
                                    <img id={item.id} className="thumbnail" src={item.url} />
                                    {item.name} &nbsp;
                                    <div className="controls">
                                    <button className="link-button"
                                        onClick={() => previewImage(item.name)}>View</button>
                                    <button className="link-button"
                                        onClick={() => removeImage(item.name)}>Remove</button>
                                    </div>
                                </li>
                            )
                        }
                        )}
                    </ul>
                </section>
                <section>
                    <h2>{props.shelfName} Image Upload Form</h2>
                    <div className='add-item'>
                        <form onSubmit={(e) => { handleSubmit(e) }}>
                            {/* Image Preview */}
                            <input
                                type="file"
                                name="imageFile"
                                placeholder="Image Filename?"
                                onClick={(e) => { e.currentTarget.value = '' }}
                            />
                            <button type="submit">Upload</button>
                            {/* Image Preview */}
                            {
                                !previewImageUrl &&
                                <div className='outerbar'>
                                    <div className='innerbar' style={{ width: `${progressPercent}%` }}>{progressPercent}%</div>
                                </div>
                            }
                            {
                                previewImageUrl &&
                                <div>
                                    <img src={previewImageUrl} alt='uploaded file' height={200} />
                                </div>
                            }
                        </form>
                    </div>
                </section>
            </div>
        </>
    )
}

function ShelfEditor(props) { // shelfPath, shelfName

    // database
    const ds = useContext(DatastoreContext);
    const shelfPath = props.shelfPath // 'recode/carousel/items'

    // Form data items (Data Out)
    const shelfEmptyTemplate = {
        title: '',
        author: '',
        imagesrc: '',
        area: '',
        category: '',
        id: ''
    }

    // hook for the forms
    const [shelfFormItem, setShelfFormItem] = useState(shelfEmptyTemplate);
    const [imageItems, setImageItems] = useState([]); 

    // hook for the data tables
    const [shelfTableItems, setshelfTableItems] = useState([]);

    // data-loading functions
    function watchShelfCollection() {
        ds.watchCollection(shelfPath, shelfTableItems, setshelfTableItems);
    }

    const updateImageFileList = () => {
        ds.getStorageFileList(shelfPath).then((fileList) => {
            let imageItems = [];
            fileList.forEach(filename => {
                    imageItems.push({
                        id: filename,
                        name: filename,
                        url: blank
                    });
            })
            imageItems.forEach(async item => {
                if (!item || !item.name) return;
                const url = await ds.getStorageFileURL(shelfPath, item.name).then((response) => {
                    if (response.status === 'success') {
                        return response.url;
                    } else {
                        return blank;
                    }
                })
                item.url = url;
            })  
            console.log(imageItems)
            setImageItems(imageItems);

        })
    }

    // Data watchers 
    useEffect(() => {
        // eslint-disable-next-line
        watchShelfCollection();
        updateImageFileList();
    }, []);

    // Data table functions
    function editShelfItem(itemId) {
        const item = shelfTableItems.find(item => item.id === itemId);
        setShelfFormItem(item);
    }
    function removeShelfItem(itemId) {
        ds.deleteDoc(shelfPath, itemId)
    }

    // Form Submission
    function handleShelfFormSubmit(e) {
        e.preventDefault();
        const data = {
            title: shelfFormItem.title,
            author: shelfFormItem.author,
            imagesrc: shelfFormItem.imagesrc,
            area: shelfFormItem.area,
            category: shelfFormItem.category
        };
        if (shelfFormItem.id !== '') {
            ds.updateDocData(shelfPath, shelfFormItem.id, data)
        } else {
            ds.addDocData(shelfPath, data);
        }
        setShelfFormItem(shelfEmptyTemplate);
    }

    return (
        <><hr />
            <div className='editor'>
                {/* shelf section */}
                <section>
                    <h2>{props.shelfName} Shelf</h2>
                    <ul className="shelf-table">
                        {shelfTableItems.map((mapItem) => {
                            return (
                                <li key={mapItem.id}>
                                    <img id={mapItem.id} className="thumbnail" src={mapItem.imagesrc} />
                                    <span className="title">{mapItem.title}</span>
                                    <span className="author">{mapItem.author}</span>
                                    
                                    <div className="controls">
                                        <button className="link-button"
                                            onClick={() => editShelfItem(mapItem.id)}>Edit</button>
                                        <button className="link-button"
                                            onClick={() => removeShelfItem(mapItem.id)}>Remove</button>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </section>
                <section>
                    <h2>{props.shelfName} Shelf Form</h2>
                    <div className='add-item'>
                        <form onSubmit={(e) => handleShelfFormSubmit(e)}>
                            <input
                                type="text"
                                name="author"
                                placeholder="Author?"
                                onChange={(e) => setShelfFormItem({ ...shelfFormItem, author: e.target.value })}
                                value={shelfFormItem.author} />
                            <input
                                type="text"
                                name="title"
                                placeholder="Title?"
                                onChange={(e) => setShelfFormItem({ ...shelfFormItem, title: e.target.value })}
                                value={shelfFormItem.title} />
                            <select
                                name="imagesrc"
                                placeholder="ImageSrc?"
                                onChange={(e) => setShelfFormItem({ ...shelfFormItem, imagesrc: e.target.value })}
                                value={shelfFormItem.imagesrc}>
                                {imageItems.map((imageItem) => {
                                    return (<option value={imageItem.url}>{imageItem.name}</option>)
                                })}
                            </select>    
                            <input
                                type="text"
                                name="area"
                                placeholder="Area?"
                                onChange={(e) => setShelfFormItem({ ...shelfFormItem, area: e.target.value })}
                                value={shelfFormItem.area} />
                            <input
                                type="text"
                                name="category"
                                placeholder="Category?"
                                onChange={(e) => setShelfFormItem({ ...shelfFormItem, category: e.target.value })}
                                value={shelfFormItem.category} />
                            <input
                                type="text"
                                name="id"
                                placeholder="ID?"
                                onChange={(e) => setShelfFormItem({ ...shelfFormItem, id: e.target.value })}
                                value={shelfFormItem.id} />
                            <button>Save</button>
                        </form>
                    </div>
                </section>
            </div>
        </>
    )
}



export default Editor;

