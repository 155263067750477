import {useState, React} from 'react';
import './aboutmodal.css';

// images
import close from '../../../src/img/icons/close_dark.png';
import codabulo from '../../../src/img/logo/codabulo_logo.png';
import recode_lt from '../../../src/img/logo/left_angle_dark.png';
import recode_gt from '../../../src/img/logo/right_angle_dark.png';


function AboutModal(props) {
    const backgroundClick = (e) => {
        if(e.target.classList.contains('modal')) {
            props.setOpen(false);        
        }
    }
    return (
            <div className="modal" onClick={(event) => backgroundClick(event)}>
                <div className="modal-content" >
                <button className="icon-button" onClick={() => props.setOpen(false)}><img className="menu-close" src={close} alt="close" /></button>
                    <img className="codabulo-logo" src={codabulo} alt="Codabulo" />
                    <div className="recode-logo-block">
                        <div className="recode-logo">
                            <img className="recode-lt" src={recode_lt} alt="" />
                            <span className="recode-logo-text">project&nbsp;re:code</span>
                            <img className="recode-gt" src={recode_gt} alt="" />
                        </div>
                    </div>
                    <div className="modal-text">

                    <hr className="navbar-line" />
                    <p><em>&lt;explore_the_edges&gt;<br /></em>
                    <em><span id="story_art_play">...through story, art and play</span></em></p>
                    <p>Ideas have a life of their own. They dance, gather, 
                       mingle and combine. 
                       New <a href="">paradigms</a> <a href="">emerge</a>.
                       The most interesting ideas collect where the edges of science, 
                       nature and culture advance. These ideas ask to be explored, played with, 
                       and ultimately,                     
                        <span className="recode-braces"> &lt;</span>
                        <span className="recode-text">re:coded</span>
                        <span className="recode-braces">&gt;</span>.</p>                    
                    
                    <p>The goal of 
                        <span className="recode-braces"> &lt;</span>
                        <span className="recode-text">project&nbsp;re:code</span>
                        <span className="recode-braces">&gt; </span>
                    is to add fuel to the fire of our collective curiosity. 
                    Read stories by the <a href="">fireside</a>. 
                    Play in the winding paths of the <a href="">forest</a>, 
                    and if it takes you, discover offerings in the curious <a href="">caves</a>.<br />
                    <em>&lt;/explore_the_edges&gt;</em></p>

                    <p><a href="">Blog</a> / <a href="">Mailing list</a> / <a href="">Discorse</a></p>
                    <p>Paradigm: a distinct set of concepts or thought patterns</p>
                    <p>Emergence: the whole becomes greater than the sum of the parts</p>

                    </div>
                </div>
            </div>          
      )
}


// // in the about modal
// function CodabuloButton() {
//     const [isOpen, setOpen] = useState(false);
//     return (
//         <div className="button-codabulo-button" 
//             aria-label="Codabulo menu" aria-expanded={isOpen?true:false} 
//             open={isOpen} onClick={() => setOpen(!isOpen)}>
//             { isOpen ? 
//             <img className="img-codabulo-logo-on" src={blank} alt="close" /> : 
//             <img className="img-codabulo-logo-off" src={blank} alt="Codabulo" /> }
//             {/* Open modal menu when isOpen otherwise null */}
//             { isOpen ? 
//             <div className="button-codabulo-modal">
//                 <ul>
//                 <li>Codabulo</li>
//                 <li>Project Waves</li>
//                 </ul>
//             </div> : null }
//         </div>
//       )
// }


export default AboutModal;