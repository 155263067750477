import { React, useState, useEffect, useContext } from 'react';
import DatastoreContext from '../../datastoreContext';
import './readerpage.css';

function Readerpage(props) {

    const ds = useContext(DatastoreContext);

    const [item, setItem] = useState({
        id: '...',
        title: '...',
        author: '...',
        category: '...',
        imagesrc: '...'
    });
    
    useEffect(() => { 
        ds.getDocData(props.collectionPath, props.docID).then(i => {
            if (i) {
                setItem(i)
            } else {
                console.log(`Error (Document Not Found): ${props.collectionPath}/${props.docID} -> ${i}`)
            }
        })                 
    },[]); 
    
    return (
        <div className="readerpage">          
        <h1>{item.title}</h1>  
        
        <img className='cover' src={item.imagesrc} alt='' />

        <ul>
            <li>Author: {item.author}</li>
            <li>Area: {item.area}</li>
            <li>Cateogry: {item.category}</li>
            <li>ID: {props.docID}</li>
        </ul>
        
        </div>        
    )
}
export default Readerpage;