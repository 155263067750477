import { React } from 'react';
import { Infopage } from '../components';
import { useParams } from "react-router-dom";

export default function Info() {
    let params = useParams();
    const collectionPath = `recode/${params.area}/${params.category}`;
    const docID = `${params.item}`;
    return (
        <div className="info">                      
            <Infopage collectionPath={collectionPath} docID={docID}/>
        </div>        
    )
};