import { React, useState, useEffect,useContext } from 'react';
import { Link } from "react-router-dom";
import DatastoreContext from '../../datastoreContext';
import Flickity from 'flickity';
import 'flickity/dist/flickity.css';
import './shelf.css';

// images
const blank = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="

function Shelf(props) {

    const ds = useContext(DatastoreContext);
    const tableItemsPath = props.tableItemsPath;
    const shelfName = props.shelfName;
    const flickityClassName = `shelf-${shelfName}`;
    const shelfClassName = `shelf-${shelfName} shelf`;
    const shelfCellClassName = `shelf-${shelfName}-cell shelf-cell`;

    // useState hooks
    const [shelfItems, setItems] = useState([]);
    
    // helper functions
    function makeShelfCell(imgSrc, title, author, area, category, id) {
        const cell = document.createElement('div');
        cell.className = shelfCellClassName;
        cell.title = title;
        cell.author = author;

        // TODO: make this a react-router anchor
        const anchor = document.createElement('a'); 
        anchor.href = ds.getReaderLink(area, category, id);

        const img = document.createElement('img');        
        img.src = imgSrc; 
        img.alt = title;
        anchor.appendChild(img);

        const text = document.createElement('div');
        text.className = 'shelfitem-title';
        text.textContent = title;        
        anchor.appendChild(text);

        cell.appendChild(anchor);

        // TODO: make this a react-router anchor
        const info_anchor = document.createElement('a'); 
        info_anchor.href = ds.getInfoLink(area, category, id);   
        info_anchor.className = 'shelfitem-info';     
        info_anchor.textContent = 'info'; 

        cell.appendChild(info_anchor);

        return cell;
    }        

    // watchers
    useEffect(() => {  
        ds.watchCollection(tableItemsPath, shelfItems, setItems);           
    },[]);

    // after updating the carousel shelfItems
    useEffect(() => {           
        const flkShelf = new Flickity( `.${flickityClassName}`, {  
            //groupCells: 3, 
            freeScroll: true,
            pageDots: false,
            prevNextButtons: false,  
            percentPosition: false,         
            contain: true
            //wrapAround: true
        });                   
        const cells = flkShelf.getCellElements();
        flkShelf.remove(cells); // remove the initial placeholder cells
        let cellElems = [];
        shelfItems.forEach(item => {
            cellElems.push(makeShelfCell(
                item.imagesrc, 
                item.title, 
                item.author,
                item.area,
                item.category, 
                item.id));
        })
        flkShelf.append(cellElems);          
        // flkShelf.on( 'staticClick', ( event, pointer, cellElement, cellIndex ) => {
        //     if ( !cellElement ) { return; }
        //     alert("Navigate to: " + cellElement.link);
        // });        
        flkShelf.next(true);
    }, [shelfItems]);    

    return (
        <div className="shelf-container">
            <h2 className={shelfName + ' shelf-name'}><Link to={`/${shelfName}`}>{shelfName}</Link></h2>
            <div className={shelfClassName}>
                <div className={shelfCellClassName}><img src={blank} alt=""/></div>
                <div className={shelfCellClassName}><img src={blank} alt=""/></div>
                <div className={shelfCellClassName}><img src={blank} alt=""/></div>                
            </div>
        </div>
    )
}

export default Shelf;