import { React } from 'react';
import { Readerpage } from '../components';
import { useParams } from "react-router-dom";

export default function Reader() {
    let params = useParams();
    const collectionPath = `recode/${params.area}/${params.category}`;
    const docID = `${params.item}`;
    return (
        <div className="reader">                      
            <Readerpage collectionPath={collectionPath} docID={docID}/>
        </div>        
    )
};