// Uses the Node.js version of firestore
import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { 
    FirebaseStorage, getStorage, ref, uploadBytesResumable, 
    uploadString, listAll, getDownloadURL, 
    deleteObject, getMetadata, updateMetadata } from "firebase/storage";
import { Analytics, getAnalytics } from "firebase/analytics";
import {
    Firestore, WhereFilterOp, Query, DocumentData,
    getFirestore, doc as fsdoc, addDoc, deleteDoc,
    updateDoc, getDoc, setDoc, getDocs, collection,
    onSnapshot, query, where, deleteField
} from 'firebase/firestore';

// if we need to use admin functions... npm install firebase-admin
//import { initializeApp, applicationDefault, cert } from "firebase-admin/app";
//import serviceAccount from './private/codabulo-recode-0e8b94584f4f.json';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Typescript
// https://www.typescriptlang.org/docs/handbook/intro.html


class Datastore {

    app: FirebaseApp;
    db: Firestore;
    auth: Auth;
    analytics: Analytics;
    storage: FirebaseStorage;

    constructor() {
        const firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID
        };
        this.app = initializeApp(firebaseConfig);
        this.auth = getAuth(this.app);
        this.analytics = getAnalytics(this.app);
        this.db = getFirestore(this.app);
        this.storage = getStorage(this.app);        
    }

    // Recode Database structure
    // > recode 
    //  - fireside
    //    [] stories
    //    [] articles
    //    [] reviews
    //    [] booklists
    //  - forest
    //    [] puzzles
    //    [] games
    //    [] recepies
    //    [] demos
    //    [] tutorials
    //  - caves
    //    [] merch
    //    [] kits
    //    [] ebooks
    //    [] artwork

    getLink(area: string, category: string, id: string) {
        const link = `${area}/${category}/${id}`
        return link;
    }
    getInfoLink(area: string, category: string, id: string) {
        const link = `info/${area}/${category}/${id}`
        return link;
    }    
    getReaderLink(area: string, category: string, id: string) {
        const link = `reader/${area}/${category}/${id}`
        return link;
    }  

    // CRUD operations
    // See: 
    //      https://retool.com/blog/crud-with-cloud-firestore-using-the-nodejs-sdk/
    //      https://firebase.google.com/docs/reference/node/firebase.firestore.Firestore#doc
    //      https://firebase.google.com/docs/firestore/manage-data/add-data


    // CREATE a document in a collection using an auto-generate docID
    // NOTE: collectionPath can also be a nested path ('col1/docID1/col2', etc)
    async addDocData(collectionPath: string, docData: any) {
        const collectionRef = collection(this.db, `${collectionPath}`);
        const res = await addDoc(collectionRef, docData);
        return res;
    }

    // READ all the docs in a collection given the collection path
    async getCollectionDocsDataList(collectionPath: string) {
        const collectionRef = collection(this.db, `${collectionPath}`);
        const collectionSnapshot = await getDocs(collectionRef);
        const docDataList = collectionSnapshot.docs.map(doc => doc.data());
        return docDataList;
    }

    // READ a single document in a collection given its path and docID
    async getDocData(collectionPath: string, docID: string) {
        const docRef = fsdoc(this.db, `${collectionPath}/${docID}`);
        const doc = await getDoc(docRef);        
        return doc.data();
    }

    // UPDATE (or add) a document to a collection using a given docID
    async updateDocData(collectionPath: string, docID: string, docData: any) {
        const docRef = fsdoc(this.db, `${collectionPath}/${docID}`)
        const res = await setDoc(docRef, docData);
        return res;
    }

    // DELETE a document from a collection using a given docID
    async deleteDoc(collectionPath: string, docID: string) {
        const docRef = fsdoc(this.db, `${collectionPath}/${docID}`)
        const res = await deleteDoc(docRef);
        return res;
    }

    // DELETE a field value from a data document 
    async deleteDocFieldValue(collectionPath: string, docID: string, fieldNameToDelete: string) {
        const docRef = fsdoc(this.db, `${collectionPath}/${docID}`)
        const res = await updateDoc(docRef, { [fieldNameToDelete]: deleteField() })
            .then(
                () => { console.log(`deleted ${fieldNameToDelete}`) }
            )
            .catch(
                () => { console.log(Error) }
            )
        return res;
    }

    // WATCH a document and update items when the document changes
    watchDocument(collectionPath: string, docID: string, items: any, setItems: any) {
        const docRef = fsdoc(this.db, `${collectionPath}/${docID}`)

        const unsubscribe = onSnapshot(docRef, (doc) => {
            const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
            const newState: any[] = items;
            for (let item in newState) {
                if (item['id'] === doc.id) {
                    const docData = doc.data();
                    // add all the document fields to the item
                    for (const [k, v] of Object.entries(docData ? docData : [])) {
                        item[k] = v;
                    }
                    item['source'] = source;
                };
            }
            setItems(newState);
            // console.log("\nUpdated items list:")
            // console.log(items)
        });

        return unsubscribe;
    }

    // WATCH a collection of documents and update component state when collection changes
    // collectionPath = 'recode/fireside/stories'
    async watchCollection(collectionPath: string, items: any, setItems: any) {
        const collectionRef = collection(this.db, `${collectionPath}`);
        const q = query(collectionRef);
        const unsubscribe = this.collectionSnapshotWatcher(q, items, setItems);
        return unsubscribe;
    }

    // WATCH a collection query and update component state when collection changes
    // collectionPath = 'recode/fireside/stories', qField = 'views', qOp = '>', qvalue = 1000
    async watchCollectionQuery(collectionPath: string, items: any, setItems: any,
        qField: string, qOp: WhereFilterOp, qValue: any) {
        const collectionRef = collection(this.db, `${collectionPath}`);
        const q = query(collectionRef, where(qField, qOp, qValue));
        const unsubscribe = this.collectionSnapshotWatcher(q, items, setItems);
        return unsubscribe;
    }

    // WATCHER helper
    private collectionSnapshotWatcher(q: Query<DocumentData>, items: any, setItems: any) {
        return onSnapshot(q, (querySnapshot) => {
            const newState: any[] = [];
            querySnapshot.forEach((doc) => {
                const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
                const item = {};
                item['id'] = doc.id;
                const docData = doc.data();
                for (const [k, v] of Object.entries(docData ? docData : [])) {
                    item[k] = v;
                }
                item['source'] = source;
                newState.push(item);
            });
            setItems(newState);
        });
    }

    //
    // Storage access
    //
    async getStorageFileList(directory: string) {
        const directoryRef = ref(this.storage, `${directory}`);
        
        return listAll(directoryRef).then((res) => {
            // res.prefixes.forEach((folderRef) => {
            // // All the prefixes under listRef.
            // // You may call listAll() recursively on them.
            // });
            const fileList: string[] = []
            res.items.forEach((itemRef) => {
                fileList.push(itemRef.name);
            });
            return fileList;
        }).catch((error) => {
            console.log(error)
            return [];
        });

    }

    async getStorageFileURL(directory: string, filename: string) {
        const storageRef = ref(this.storage, `${directory}/${filename}`);
        return getDownloadURL(storageRef).then((url) => {
            const response = { status: 'success', url: url }
            return response;
        }).catch((error) => {            
            // see: https://firebase.google.com/docs/storage/web/handle-errors
            let response = {};
            switch (error.code) {
                case 'storage/object-not-found':
                    response = { status: 'object-not-found', url: '' }
                    break;
                case 'storage/unauthorized':                    
                    response = { status: 'unauthorized', url: '' }
                    break;
                case 'storage/canceled':
                    response = { status: 'canceled', url: '' }
                    break;
                case 'storage/unknown':
                    response = { status: 'unknown', url: '' }
                    break;
            }
            return response;
        });
    }

    async setStorageFile(directory: string, filename: string, data: File) {
        const storageRef = ref(this.storage, `${directory}/${filename}`);
        uploadBytesResumable(storageRef, data).then((snapshot) => {
            console.log('Uploaded a blob or file!');
        });
    }

    setStorageFileImage(directory: string, filename: string, imageData: File) {
        const storageRef = ref(this.storage, `${directory}/${filename}`);
        const uploadTask = uploadBytesResumable(storageRef, imageData);
        return uploadTask;
    }

    async setStorageFileString(directory: string, filename: string, data: string) {
        const storageRef = ref(this.storage, `${directory}/${filename}`);
        uploadString(storageRef, data).then((snapshot) => {            
            console.log(`Uploaded string data to ${directory}/${filename}`);
        });
    }

    async setStorageFileBase64String(directory: string, filename: string, data: string) {
        const storageRef = ref(this.storage, `${directory}/${filename}`);
        //const message4 = 'data:text/plain;base64,5b6p5Y+344GX44G+44GX44Gf77yB44GK44KB44Gn44Go44GG77yB';
        uploadString(storageRef, data, 'data_url').then((snapshot) => {
            console.log(`Uploaded base64string data to ${directory}/${filename}`);
        });
    }    

    async deleteStorageFile(directory: string, filename: string) {
        const storageRef = ref(this.storage, `${directory}/${filename}`);
        return deleteObject(storageRef).then(() => {
            console.log('Deleted ' + `${directory}/${filename}`);
            return { status: 'success' } // File deleted successfully
        }).catch((error) => {
            console.log(error + ' error getting file metadata for ' + filename);
            return { status: 'error' }
        });
    }

    async getStorageFileMetadata(directory: string, filename: string) {
        const storageRef = ref(this.storage, `${directory}/${filename}`);
        return getMetadata(storageRef).then((metadata) => {
            return metadata;
        }).catch((error) => {
            console.log(error + ' error getting file metadata for ' + filename);
        });
    }

    async updateStorgeFileMetadata(directory: string, filename: string, newMetadata: {}) {
        const storageRef = ref(this.storage, `${directory}/${filename}`);
        // const newMetadata = {
        //     cacheControl: 'public,max-age=300',
        //     contentType: 'image/jpeg'
        // };
        return updateMetadata(storageRef, newMetadata).then((metadata) => {
            return metadata;
        }).catch((error) => {
            console.log(error + ' error updating file metadata for ' + filename);
        });
    }

    async deleteStorageFileMetadata(directory: string, filename: string) {
        const storageRef = ref(this.storage, `${directory}/${filename}`);
        const deleteMetadata = {
            contentType: '' // null
        };
        updateMetadata(storageRef, deleteMetadata).then((metadata) => {
            // metadata.contentType should be null
            }).catch((error) => {
                console.log(error + ' error deleting file metadata for ' + filename);
            });
    }
}

export default Datastore;
