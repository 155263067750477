import React from 'react';
import ReactDOM from 'react-dom/client';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import reportWebVitals from './reportWebVitals';
import './theme.css';
import './index.css';


// routes
import Root from "./routes/root";
import Fireside from "./routes/fireside";
import Forest from "./routes/forest";
import Caves from "./routes/caves";
import Info from "./routes/info";
import Reader from "./routes/reader";
import Account from "./routes/account";
import Editor from "./routes/editor";
import ErrorPage from "./routes/error-page";

// components
import { Homepage } from './components';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Homepage />,
        errorElement: <div>Home: Oops! There was an error.</div>,
      },
      {
        path: "fireside",
        element: <Fireside />,
        errorElement: <div>Fireside: Oops! There was an error.</div>,
      },       
      {
        path: "forest",
        element: <Forest />,
        errorElement: <div>Forest: Oops! There was an error.</div>,
      },       
      {
        path: "caves",
        element: <Caves />,
        errorElement: <div>Caves: Oops! There was an error.</div>,
      },       
      {
        path: "info/:area/:category/:item",
        element: <Info />,
        errorElement: <div>Info: Oops! There was an error.</div>,
      },       
      {
        path: "reader/:area/:category/:item",
        element: <Reader />,
        errorElement: <div>Reader: Oops! There was an error.</div>,
      },      
      {
        path: "account",
        element: <Account />,
        errorElement: <div>Account: Oops! There was an error.</div>,
      },

      {
        path: "editor",
        element: <Editor />,
        errorElement: <div>Editor: Oops! There was an error.</div>,
      },      
    ],    
  },  
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
