import { React, useState, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import DatastoreContext from '../../datastoreContext';
import './accountpage.css';

function Accountpage(props) {

    const ds = useContext(DatastoreContext);

    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState('')

    onAuthStateChanged(ds.auth, (user) => {
        if (user) {
          if (!loggedIn) {
              setLoggedIn(true);
              setUser(user); // https://firebase.google.com/docs/reference/js/firebase.User
          }
        } else {
          if (loggedIn) {
              setLoggedIn(false);
              setUser('');
          }        
        }
      });    

    return (
        <div className="accountpage">   
        <h2>Account</h2>  
        <ul>
            <li>UID: {user.uid}</li>
            <li>Name: {user.displayName}</li>
            <li>Email: {user.email}</li>
            <li>Image: <img src={user.photoURL} alt={user.displayName} /></li>
        </ul>
        </div>        
    )
}
export default Accountpage;