import { React, useState, useEffect, useContext } from 'react';
import { onAuthStateChanged, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import { Link, useNavigate, useLocation } from "react-router-dom";
import AboutModal from '../aboutmodal';
import DatastoreContext from '../../datastoreContext';
import './navbar.css';

// Images
import projectrecode_off_dark from '../../../src/img/logo/recode_mini_dark1.png';
import projectrecode_off_light from '../../../src/img/logo/recode_mini_light1.png';

import projectrecode_on_dark from '../../../src/img/logo/recode_mini_dark2.png';
import projectrecode_on_light from '../../../src/img/logo/recode_mini_light1.png';

import back_dark from '../../../src/img/icons/back_dark.png';
import back_light from '../../../src/img/icons/back_light.png';

import menu_off_dark from '../../../src/img/icons/menu_dark.png';
import menu_off_light from '../../../src/img/icons/menu_light.png';

import close_dark from '../../../src/img/icons/close_dark.png';
import close_light from '../../../src/img/icons/close_light.png';

// import search_on_dark from '../../../src/img/icons/search_off_dark.png';
// import search_on_light from '../../../src/img/icons/search_off_dark.png';

import search_off_dark from '../../../src/img/icons/search_off_dark.png';
import search_off_light from '../../../src/img/icons/search_off_light.png';

import search from '../../../src/img/icons/search.png';

// const blank = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="

function NavBar(props) {

    // react router
    const navigate = useNavigate();
    const location = useLocation()

    // hooks
    const [isShowAboutOpen, setShowAboutOpen] = useState(false)
    const [page, setPage] = useState(location.pathname); 
    const [searchOn, setSearchOn] = useState(false);   
    useEffect(() => { 
        setPage(location.pathname);       
    },[location]); 

    // add on scroll class
    useEffect(() => {
        window.onscroll = () => {
            if (document.body.scrollTop > 5 || document.documentElement.scrollTop > 5) {
                document.getElementById("topnavbar").classList.add("navscroll");
            } else {
                document.getElementById("topnavbar").classList.remove("navscroll");
            }
        }    
    
    })    

    return (
        <div id="topnavbar" className="navbar">         

            <div className="button-navbar-back">
                { page === "/" ? null :
                <button type="button" className="link-button" onClick={() => {                    
                  navigate(-1);
                }}
              ><img className="img-back" src={
                    props.theme==="light" ?
                    back_light : 
                    back_dark
                } alt="back" /></button> }
            </div>

            <div className="navbar-project-name-logo">
                { page !== "/" ? null :
                <ProjectNameButton 
                    isShowAboutOpen={isShowAboutOpen} 
                    setShowAboutOpen={(isOpen) => setShowAboutOpen(isOpen)}
                    theme={props.theme} /> }
            </div>

            <div className="navbar-search-button">
                { searchOn ? 
                <SearchButton 
                    theme={props.theme} /> :
                null
                }
            </div>     

            <div className="navbar-menu-button">
                <MenuButton 
                    page={page}
                    setPage={page => setPage(page)} 
                    setShowAboutOpen={(isOpen) => setShowAboutOpen(isOpen)}
                    theme={props.theme} 
                    setTheme={theme => props.setTheme(theme)} />
            </div>  

            {/* <hr className="navbar-line"/> */}
        </div>        
    )
}

function ProjectNameButton(props) {

    return (
        <div className="button-projectName-button" 
            aria-label="project recode" aria-expanded={props.isShowAboutOpen?true:false} 
            open={props.isShowAboutOpen}>
            { props.isShowAboutOpen ? 
            <>
            <button onClick={() => props.setShowAboutOpen(false)} className="icon-button">
                    <img className="img-projectrecode-off" src={
                        props.theme==="light" ?
                        projectrecode_on_light : 
                        projectrecode_on_dark
                        } alt="project re:code" />
            </button>
            <AboutModal setOpen={props.setShowAboutOpen} />
            </> : 
            <button onClick={() => props.setShowAboutOpen(true)} className="icon-button">
                <img className="img-projectrecode-off" src={
                        props.theme==="light" ?
                        projectrecode_off_light : 
                        projectrecode_off_dark
                        } alt="project re:code" />
            </button> }
        </div>
      )
}

function MenuButton(props) {
    
    const ds = useContext(DatastoreContext);
    const provider = new GoogleAuthProvider();

    // menu open/close
    const [isOpen, setOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState('')
       
    const backgroundClick = (e) => {
        if(e.target.classList.contains('modal')) {
            setOpen(false);        
        }
    }

    // signin / signout
    // https://firebase.google.com/docs/reference/js/firebase.User

    const menuSignInButton = () => {    
        signInWithPopup(ds.auth, provider)
            .then((result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                if (!loggedIn) {
                    setLoggedIn(true);                
                    setUser(result.user);
                    setOpen(false);
                }
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.customData.email;
                const credential = GoogleAuthProvider.credentialFromError(error);
                alert(errorMessage);
            });
    } 

    const menuSignOutButton = () => {
        signOut(ds.auth).then(() => {
            setLoggedIn(false);
            setUser('');        
        }).catch((error) => {
        // An error happened.
        });
    }

    // about
    const menuSetShowAboutOpen = () => {
        setOpen(false)
        props.setShowAboutOpen(true)
    }

    // page navigation
    const setPage = (page) => {
        props.setPage(page)
    }

    // darkmode
    const toggleTheme = () => {
        if (props.theme === 'light') {
          props.setTheme('dark');
        } else {
          props.setTheme('light');
        }
      };

    return (
        <div className="button-menu-button" 
            aria-label="menu" aria-expanded={isOpen?true:false} 
            open={isOpen}>
            { isOpen ? 
            <div className="modal" onClick={(event) => backgroundClick(event)}>
                <div className="menu-modal-content">
                <button className="icon-button menu-close" onClick={() => setOpen(false) }><img src={
                        props.theme==="light" ?
                        close_light : 
                        close_dark
                        } alt="close" /></button>
                    <ul>
                        
                    {/* Account ? Sign in / Register */}
                    { loggedIn ? 
                    <>
                    <li><Link to={`account`} onClick={() => {
                        setPage(`account`);
                        setOpen(false); 
                        }}>
                        <div className='menu-user'>
                            <img src={user.photoURL} alt={user.displayName} />
                            <div >{user.displayName}</div>
                        </div>
                        </Link></li>
                    <li><button 
                            className="link-button" 
                            onClick={() => menuSignOutButton()}>sign out</button></li>    
                    </>
                        : 
                    <>
                    <li><button 
                            className="link-button" 
                            onClick={() => menuSignInButton()}>sign in</button></li>
                    </>
                    }                    

                    <li><hr className="thin" /></li>
                    
                    {/* Home / Fireside / Forest / Cave */}
                    { props.page !==`/` ? 
                    <li><Link className='Home' to={`/`} onClick={() => {
                        setPage(`/`); 
                        setOpen(false);
                        }}>home</Link></li>
                        : null 
                    }
                    { props.page !==`/fireside` ?                                        
                    <li><Link className='Fireside' to={`fireside`} onClick={() => {
                        setPage(`fireside`); 
                        setOpen(false);
                        }}>fireside</Link></li>
                        : null 
                    }
                    { props.page !==`/forest` ?
                    <li><Link className='Forest' to={`forest`} onClick={() => {
                        setPage(`forest`); 
                        setOpen(false);
                        }}>forest</Link></li>
                        : null 
                    }
                    { props.page !==`/caves` ?
                    <li><Link className='Caves' to={`caves`} onClick={() => {
                        setPage(`caves`); 
                        setOpen(false);
                        }}>caves</Link></li> :
                        null
                    }                        

                    <li><hr className="thin" /></li>

                    <li><button 
                            className="link-button" 
                            onClick={() => menuSetShowAboutOpen()}>about</button></li>

                    <li><button 
                            className="link-button" 
                            onClick={() => toggleTheme()}>{ 
                            props.theme === 'light' ?
                             "dark mode" :
                             "light mode" }</button></li> 
                    
                    <li><Link to={`editor`} onClick={() => {
                        setPage(`editor`); 
                        setOpen(false);
                        }}>editor</Link></li>                             
                    
                    </ul>

                </div>
            </div> : 
            <button className="icon-button" onClick={() => setOpen(true)}>
                <img className="img-menu-off" src={
                        props.theme==="light" ?
                        menu_off_light : 
                        menu_off_dark
                        } alt="menu" /></button> }
        </div>
      )
}

function SearchButton(props) {
    const [isOpen, setOpen] = useState(false);  
    const backgroundClick = (e) => {
        if(e.target.classList.contains('modal')) {
            setOpen(false);        
        }
    }
    return (
        <div className="button-search-button" 
            aria-label="search" aria-expanded={isOpen?true:false} 
            open={isOpen}>
            { isOpen ? 
            <div className="modal" onClick={(event) => backgroundClick(event)}>
                <div className="search-modal-content">
                    <div className="search-close" onClick={() => setOpen(false)}>
                        <button className="icon-button"><img src={
                        props.theme==="light" ?
                        close_light : 
                        close_dark
                        } alt="close" /></button>
                    </div>
                    <form id="search-form" action="/action_page.php">
                        <div className="search-form-content">
                            <div className="search-input">
                                <input type="text" placeholder="Search..." name="search" />
                            </div>
                            <div className="search-submit">
                                <button className="search-modal-content-button" type="submit"><img src={search} alt="close" /></button>
                            </div>                    
                        </div>
                    </form>
                </div>
            </div> : 
            <img className="img-search-off" onClick={() => setOpen(true)} src={
                props.theme==="light" ?
                search_off_light : 
                search_off_dark
                } alt="search" /> }
        </div>
      )
}

// // in the about modal
// function CodabuloButton() {
//     const [isOpen, setOpen] = useState(false);
//     return (
//         <div className="button-codabulo-button" 
//             aria-label="Codabulo menu" aria-expanded={isOpen?true:false} 
//             open={isOpen} onClick={() => setOpen(!isOpen)}>
//             { isOpen ? 
//             <img className="img-codabulo-logo-on" src={blank} alt="close" /> : 
//             <img className="img-codabulo-logo-off" src={blank} alt="Codabulo" /> }
//             {/* Open modal menu when isOpen otherwise null */}
//             { isOpen ? 
//             <div className="button-codabulo-modal">
//                 <ul>
//                 <li>Codabulo</li>
//                 <li>Project Waves</li>
//                 </ul>
//             </div> : null }
//         </div>
//       )
// }

// // in the menu modal
// function AccountButton() {
//     const [isOpen, setOpen] = useState(false);
//     const isAuth = false;
//     return (
//         <div className="button-account-button" 
//             aria-label="Toggle menu" aria-expanded={isOpen?true:false} 
//             open={isOpen} onClick={() => setOpen(!isOpen)}>
//             { isAuth ?         
//                 ( isOpen ? 
//                 <img className="img-account-on" src={blank} alt="close" /> : 
//                 <img className="img-account-off" src={blank} alt="account" /> )
//                 // Open modal menu when isOpen otherwise null 
//                 ( isOpen ? 
//                 <div className="button-account-modal">
//                     <ul>
//                     <li>Item 1</li>
//                     <li>Item 2</li>
//                     </ul>
//                 </div> : null ) :               
//             <SignInButton />
//             }
//         </div>
//       )
// }

// function SignInButton() {
//     const [isOpen, setOpen] = useState(false);
//     return (
//         <div className="button-signin-button" 
//         aria-label="sign in" aria-expanded={isOpen?true:false}
//         open={isOpen} onClick={() => setOpen(!isOpen)}>
//             { isOpen ? 
//             <img className="img-signin-on" src={blank} alt="close" /> : 
//             <img className="img-signin-off" src={blank} alt="sign in" /> }
//             {/* Open modal menu when isOpen otherwise null */}
//             { isOpen ? 
//             <div className="button-signin-modal">
//                 <ul>
//                 <li>Username</li>
//                 <li>Password</li>
//                 </ul>
//             </div> : null }            
//         </div>
//     )
// }

export default NavBar;